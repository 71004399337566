/* ============================ sliders.js START ================================================================= */
const experience = new Swiper('.slider-experience', {
   // Default parameters
   // slidesPerView: 1,
   // watchOverflow: true,
   spaceBetween: 10,
   speed: 300,
   loop: true,
   // Navigation arrows
   navigation: {
      nextEl: '.slider-experience__button-next',
      prevEl: '.slider-experience__button-prev',
   },
   breakpoints: {
      // when window width is >= 320px
      320: {
         slidesPerView: 1,
         spaceBetween: 10,
      },
      // when window width is >= 480px
      428: {
         slidesPerView: 2,
         spaceBetween: 10,
      },
      667: {
         slidesPerView: 3,
         spaceBetween: 20,
      },
      1440: {
         slidesPerView: 3,
         spaceBetween: 30,
      },
   },
   pagination: {
      el: '.slider-experience__pagination',
      type: 'bullets',
      clickable: true,
   },
   autoplay: {
      delay: 5000,
      stopOnLastSlide: true,
      disableOnInteraction: false,
   },
   keyboard: {
      enabled: true,
      onlyInViewport: false,
   },

})
const reviews = new Swiper('.slider-reviews', {
   // Default parameters
   slidesPerView: 1,
   watchOverflow: true,
   spaceBetween: 10,
   speed: 300,
   loop: true,
   pagination: {
      el: '.slider-reviews__pagination',
      type: 'bullets',
      clickable: true,
   },
   autoHeight: true,
   // autoplay: {
   //    delay: 5000,
   //    stopOnLastSlide: true,
   //    disableOnInteraction: false,
   // },
   keyboard: {
      enabled: true,
      onlyInViewport: false,
   },

})

/* ============================ sliders.js END ================================================================= */

