$(document).ready(function () {
   console.log('ready');
   handleBurgerMenu()
   // searchElDropdown() // hide the selected value in the select-dropdown list
   tabsFAQInfo()
   validate('.form', {submitFunction: validationCallForm})
   validTextNumbers()
   privacyPolicyCheck()
   calendarPicker()
   bottomDataCalendar()
   topDataCalendar()
   isCaptainIncluded()
   selectGuestsHandler()
   selectTimeHandler()
   clearCalendarDatesHandler()
});

$(window).on('load', function () {
   console.log('load')
})

function handleBurgerMenu() {

   $(document).on('click', '.hamburger__label', function () {
      $('.menu')
         .toggleClass('menu-open')
      $('body').toggleClass('no-scroll');
   });
}

function tabsFAQInfo() {

   const textArr = $('.info-faq__text[data-slide]');
   const btnArr = $('.info-faq__btn[data-slide]');
   const itemArr = $('.info-faq__item[data-slide]');

   $('.info-faq__list').each(function () {
      const ths = $(this);
      ths.find('.info-faq__text').hide();

      ths.find('.info-faq__btn').click(function () {
         const thsBtn = $(this);
         const btnValue = thsBtn.data('slide');
         thsBtn.toggleClass('active');

         textArr.each(function () {
            const thsText = $(this);
            const textValue = thsText.data('slide');
            if (btnValue === textValue) thsText.slideToggle(300);
         });

         itemArr.each(function () {
            const thsItem = $(this);
            const textValue = thsItem.data('slide');
            if (btnValue === textValue) thsItem.toggleClass('active');
         });
      });

      ths.find('.info-faq__title').click(function () {
         const thsTitle = $(this);
         const titleValue = thsTitle.data('slide');

         textArr.each(function () {
            const thsText = $(this);
            const textValue = thsText.data('slide');
            if (titleValue === textValue) thsText.slideToggle(300);
         });

         btnArr.each(function () {
            const thsBtn = $(this);
            const btnValue = thsBtn.data('slide');
            if (titleValue === btnValue) thsBtn.toggleClass('active');
         });

         itemArr.each(function () {
            const thsItem = $(this);
            const textValue = thsItem.data('slide');
            if (titleValue === textValue) thsItem.toggleClass('active');
         });

      });
   });
}

function privacyPolicyCheck() {
   $(document).on('change input', '#checkbox', function () {
      const ths = $(this);
      const button = $('.form__button')

      if (ths.is(':checked')) {
         button.prop('disabled', false);
      } else {
         button.prop('disabled', true);
      }
   })
}

$(document).on('click', '.menu__item', closeMenu)

function closeMenu() {
   if ($(window).width() <= 666) {
      $('.hamburger__label').trigger("click");
   }
}

function isCaptainIncluded() {
   const checkboxTop = refs.checkboxTopCaptain
   const checkboxBottom = refs.checkboxBottomCaptain
   const captain = $('[data-captain]')

   if (checkboxTop.is(':checked')) {
      captain.removeClass('disabled')
   } else {
      captain.addClass('disabled')
   }

   if (checkboxBottom.is(':checked')) {
      captain.removeClass('disabled')
   } else {
      captain.addClass('disabled')
   }

   $(document).on('change', '#captain-top-checkbox', function () {
      const ths = $(this);

      const isChecked = ths.prop('checked');

      captain.toggleClass('disabled')

      if (isChecked) {
         refs.checkboxBottomCaptain.prop('checked', true);
      } else {
         refs.checkboxBottomCaptain.prop('checked', false);
      }
   })

   $(document).on('change', '#captain-bottom-checkbox', function () {
      const ths = $(this);

      const isChecked = ths.prop('checked');

      captain.toggleClass('disabled')

      if (isChecked) {
         refs.checkboxTopCaptain.prop('checked', true);
      } else {
         refs.checkboxTopCaptain.prop('checked', false);
      }
   })
}

function selectGuestsHandler() {
   $(document).on('change', '[data-bottom-guests]', function () {
      const ths = $(this);

      const val = ths.val()

      refs.selectTopGuests.val(val)
   })

   $(document).on('change', '[data-top-guests]', function () {
      const ths = $(this);

      const val = ths.val()

      refs.selectBottomGuests.val(val)
   })
}

function selectTimeHandler() {
   $(document).on('change', '[data-bottom-time]', function () {
      const ths = $(this);

      const val = ths.val()

      refs.selectTopTime.val(val)
   })

   $(document).on('change', '[data-top-time]', function () {
      const ths = $(this);

      const val = ths.val()

      refs.selectBottomTime.val(val)
   })
}