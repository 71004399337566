/* ============================ date-picker START ================================================================= */
const showDate = $('.calendar__data')
let startDate = moment()
let endDate = moment()

const bottomDataPicker = $('#bottomDataPicker')
const bottomDataPickerText = $('#bottomDataPicker > span')

const topDataPicker = $('#topDataPicker')
const topDataPickerText = $('#topDataPicker > span')

const calendar = $('#calendar')

const disabledDates = ['05/12/2023', '05/24/2023', '05/10/2023','05/15/2023']

function calendarPicker() {
   function setData(start, end) {
      bottomDataPickerText.html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'))
      showDate.html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'));
      topDataPickerText.html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'));
   }

   calendar.daterangepicker({
      autoApply: true,
      autoUpdateInput: false,
      startDate: startDate,
      endDate: endDate,
      buttonClasses: "btn btn-sm apply",
      cancelClass: "btn-default close",
      parentEl: $('.calendar__wrapper'),
      isInvalidDate: function (date) {
         // Check if the selected date is in the disabled dates array
         return disabledDates.includes(date.format('MM/DD/YYYY'));
      }
   }, function (start, end) {
      bottomDataPickerText.html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'))
      topDataPickerText.html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'))
      showDate.html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'));

      startDate = start
      endDate = end
   });

   setData(startDate, endDate);

   calendar.on('change.daterangepicker', function (ev, picker) {

      if (picker.startDate && picker.endDate) {
         // Get the selected dates from the picker
         var startDate = picker.startDate.format('YYYY-MM-DD');
         var endDate = picker.endDate.format('YYYY-MM-DD');
         console.log('startDate', startDate)
      }

   });

   calendar.data('daterangepicker').show();

   // we do not close outside the calendar
   $(document).off('mousedown.daterangepicker touchend.daterangepicker keydown.daterangepicker');

   // Prevent calendar from closing when user clicks "Apply"
   calendar.on('apply.daterangepicker', changeDateHandler);
}

function topDataCalendar() {
   function setData(start, end) {
      topDataPickerText.html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'))
      startDate = start
      endDate = end
   }

   topDataPicker.daterangepicker({
      autoApply: true,
      startDate: startDate,
      endDate: endDate,
      opens: 'left',
      parentEl: $('[data-top-picker]'),
      isInvalidDate: function (date) {
         return disabledDates.includes(date.format('MM/DD/YYYY'));
      }
   }, setData);

   topDataPicker.on('apply.daterangepicker', changeDateHandler);
}

function bottomDataCalendar() {
   function setData(start, end) {
      bottomDataPickerText.html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'))
      showDate.html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'));
      startDate = start
      endDate = end
   }

   bottomDataPicker.daterangepicker({
      autoApply: true,
      startDate: startDate,
      endDate: endDate,
      opens: 'left',
      parentEl: $('[data-bottom-picker]'),
      isInvalidDate: function (date) {
         return disabledDates.includes(date.format('MM/DD/YYYY'));
      }
   }, setData);

   bottomDataPicker.on('apply.daterangepicker', changeDateHandler);
}

function changeDateHandler(event, picker) {
   event.preventDefault();

   let hasDisabledDays = false;
   const currentDate = startDate.clone();

   while (currentDate.isSameOrBefore(endDate)) {
      if (picker.isInvalidDate(currentDate)) {
         hasDisabledDays = true;
         break;
      }
      currentDate.add(1, 'day');
   }

   if (hasDisabledDays) {
      disabledRequest()
   } else {
      enabledRequest()
   }

   let newStartDate = picker.startDate;
   let newEndDate = picker.endDate;

   topDataPicker.data('daterangepicker').setStartDate(newStartDate);
   topDataPicker.data('daterangepicker').setEndDate(newEndDate);

   bottomDataPicker.data('daterangepicker').setStartDate(newStartDate);
   bottomDataPicker.data('daterangepicker').setEndDate(newEndDate);

   calendar.data('daterangepicker').setStartDate(newStartDate);
   calendar.data('daterangepicker').setEndDate(newEndDate);
   calendar.data('daterangepicker').hide();
   calendar.data('daterangepicker').show();

   $(document).off('mousedown.daterangepicker touchend.daterangepicker keydown.daterangepicker');
}

function disabledRequest() {
   refs.requestBooking.each(function () {
      const ths = $(this);
      ths.addClass('disabled')
   })

   refs.calendarErrorText.each(function () {
      const ths = $(this);
      ths.addClass('active')
   })
   refs.calendarTitle.text('Selected dates are incorrect')

   refs.calendarTitle.addClass('disabled')
}

function enabledRequest() {
   refs.requestBooking.each(function () {
      const ths = $(this);
      ths.removeClass('disabled')
   })

   refs.calendarErrorText.each(function () {
      const ths = $(this);
      ths.removeClass('active')
   })

   refs.calendarTitle.text('Select your trip dates')

   refs.calendarTitle.removeClass('disabled')
}

function clearCalendarDatesHandler() {
   function resetDates() {
      const startDate = moment()
      const endDate = moment()

      calendar.data('daterangepicker').setStartDate(startDate);
      calendar.data('daterangepicker').setEndDate(endDate);

      calendar.data('daterangepicker').hide();
      calendar.data('daterangepicker').show();
      $(document).off('mousedown.daterangepicker touchend.daterangepicker keydown.daterangepicker');


      bottomDataPicker.data('daterangepicker').setStartDate(startDate);
      bottomDataPicker.data('daterangepicker').setEndDate(endDate);

      topDataPicker.data('daterangepicker').setStartDate(startDate);
      topDataPicker.data('daterangepicker').setEndDate(endDate);

      enabledRequest()
   }

   $(document).on('click', '[data-clear-calendar]', function () {
      resetDates();
   })

}

/* ============================ date-picker END ================================================================= */

