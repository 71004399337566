"use strict";
/* ============================ refs START ================================================================= */
const refs = Object.freeze({
   dropDownList: $('.dropdown__list'),
   dropDownBtn: $('.dropdown__button'),
   dropDownItems: $('.dropdown__item'),
   dropDownInput: $('.dropdown__input-hidden'),
   footer: $('footer'),
   selectTopGuests: $('[data-top-guests]'),
   selectBottomGuests: $('[data-bottom-guests]'),
   selectTopTime: $('[data-top-time]'),
   selectBottomTime: $('[data-bottom-time]'),
   checkboxTopCaptain: $('[data-top-captain]'),
   checkboxBottomCaptain: $('[data-bottom-captain]'),
   calendarTitle: $('[data-calendar-title]'),
   requestBooking: $('[data-request-booking]'),
   calendarErrorText: $('[data-calendar-error]'),
})

/* ============================ refs END ================================================================= */
